.en {
  font-family: 'Sorts Mill Goudy', serif;
}

.zh {
  font-family: 'Noto Sans TC', sans-serif;
}

.sc {
  font-family: 'Noto Sans SC', sans-serif;
}

@keyframes bkg-zoom {
  from {
    background-size: 110%;
  }
  to {
    background-size: 100%;
  }
}

@keyframes bkg-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.layout-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  overflow: hidden;
  // background-size: 110%;
  // opacity: 0;
  // animation: bkg-zoom 5s, bkg-opacity 1s;
  animation-fill-mode: forwards;
  // animation-delay: bkg-zoom 1s, bkg-opacity 0.5s;

  @media only screen and (max-width: 820px) {
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    background-position: top center !important;
  }

  @media only screen and (max-width: 414px) {
    background-size: 100%;
  }
}

.yohohub2-logo {
  position: absolute;
  top: 5px;
  left: 0;
  width: 15vw;
  min-width: 220px;
  z-index: 100;

  @media only screen and (max-width: 820px) {
    width: 36%;
    margin: 10px;
    z-index: 20;
    min-width: unset;
  }

  @media only screen and (max-width: 479px) {
    width: 32%;
  }
}

.information-drawer {
  position: fixed;
  height: 280px;
  width: 100%;
  bottom: 0;
  background-color: rgba(56, 27, 67, 0.93);
  padding: 2% 3% 1%;
  font-size: 1.6vw;
  z-index: 20;
  &.en {
    font-family: 'Roboto Condensed', sans-serif;
  }

  p {
    width: 70%;
    font-size: 18px;
    line-height: 1.14;
  }
}

.information-drawer-content {
  position: absolute;
  position: absolute;
  height: 150%;
  overflow: scroll;
  width: 220%;
  top: -220px;
  left: 32px;
  padding-right: 32px;
  transform-origin: 0 100% 0;
  transform: scale(0.5);
}

.information-drawer-content-title {
  text-decoration: underline;
  font-size: 20px;
  line-height: 2;
  letter-spacing: 1px;
  text-decoration: underline;
}

.popup-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 30;
}

.popup-close-icon {
  position: absolute;
  margin: 20px 30px;
  width: 30px;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 50;

  &.information {
    top: 4%;
  }

  @media only screen and (max-width: 820px) {
    width: 5%;
    top: 20vw;
    margin: 20px 40px;
  }

  @media only screen and (max-width: 479px) {
    margin: 20px;
  }
}

.page-wrapper {
  @media only screen and (max-width: 820px) {
    &.popup-visible {
      position: absolute;
      top: 0;
    }
  }
}

.information-drawer-close-icon {
  position: absolute;
  margin: 2%;
  top: 0;
  right: 6vw;
  width: 1vw;
  min-width: 20px;
  cursor: pointer;
  z-index: 20;
}

.curtain {
  position: absolute;
  width: 100vw;
  height: 0px;
  top: 0;
  left: 0;
  background-color: rgba(56, 27, 66, 0.85);
  z-index: 50;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  backdrop-filter: blur(16px);
}

@keyframes curtainRollDown {
  0% {
    height: 0px;
  }

  100% {
    height: 100vh;
  }
}

@keyframes curtainRollUp {
  0% {
    height: 100vh;
  }

  100% {
    height: 0px;
  }
}
