.so-wrapper {
}

.nav-list-wrapper-so {
  width: 28vw;
  min-width: 320px;
  top: 20%;
  right: 0%;

  &.lang-en {
    width: 34vw;
    left: 64%;
  }

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 66%;
    min-width: unset;
    left: 6%;
    margin-top: 65%;

    &.popup-visible {
      margin-top: 10%;
    }
  }

  @media only screen and (max-width: 414px) {
  }
}

.nav-list-divider-so {
  color: #00726f;

  @media only screen and (max-width: 820px) {
    margin: 6% 0;
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh,
.sc {
  .slider-title-so {
    font-size: calc(0px + 2vw);
  }
}

.slider-title-so {
  font-size: calc(28px + 2vw);
  color: #00726f;

  @media only screen and (max-width: 820px) {
    font-size: 7vw !important;
  }

  @media only screen and (max-width: 414px) {
  }
}

.video-so {
  position: absolute;
  top: 18%;
  left: 0;
  width: 780px;

  @media only screen and (max-width: 820px) {
  }

  @media only screen and (max-width: 414px) {
  }
}

.so-content {
  position: absolute;
  width: 93%;
  height: 50%;
  top: 20%;
  left: 50%;
  max-width: 1080px;
  transform: translateX(-50%);

  @media only screen and (max-width: 820px) {
    width: 80%;
    height: 80%;
    top: 4%;
    left: 10%;
    position: relative;
    overflow: unset;
    margin-top: 60%;
    max-width: none;
    transform: none;
  }
}

.iframe-wrapper-so {
  position: absolute;
  width: 54vw;
  height: 100%;
  left: 0;
  display: flex;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 100%;
    min-width: unset;
    margin-top: 10%;
  }
}

.iframe-so {
  width: 54vw;
  height: calc((54vw * 9) / 16);
  align-self: center;

  @media only screen and (max-width: 820px) {
    width: 90vw;
    height: calc((90vw * 9) / 16);
  }
}
