.alpha-video-wrapper {
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(45deg, #cccccc 25%, transparent 1),
    linear-gradient(-45deg, #cccccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #cccccc 75%),
    linear-gradient(-45deg, transparent 75%, #cccccc 75%);
  background-size: 16px 16px;
  background-position: 0 0, 0 8px, 8px -8px, -8px 0px;
}

.alpha-video {
  object-fit: fill;
}
