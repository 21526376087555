.pl-wrapper {
}

.nav-list-wrapper-pl {
  width: 26%;
  min-width: 300px;
  top: 9%;
  right: 12%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 80%;
    min-width: unset;
    left: 20%;
    right: unset;
    top: 8%;
    margin-top: 160%;
  }
}

.nav-list-divider-pl {
  color: #a22078;

  @media only screen and (max-width: 820px) {
    margin: 6% 0
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-pl  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-pl {
  font-size: calc(28px + 2vw);
  color: #a22078;

  @media only screen and (max-width: 820px) {
    font-size: 7vw!important;
  }

  @media only screen and (max-width: 414px) {
  }
}
