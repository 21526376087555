.popup-wrapper-sm {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 40;

  &.popup-visible {
    position: relative;
  }
}

.popup-frame-img-sm {
  width: 100%;
}

.popup-content-wrapper-sm {
  width: 100%;
  height: auto;
  font-size: 9px;
  background-image: url('/img/pop_bkg_mid.png');
  background-size: cover;

  &.lang-en {
    font-family: 'Helvetica Neue', 'Arial', sans-serif;
  }
}

.popup-content-sm {
  left: 22%;
  margin: auto;
  z-index: 10;
  width: 86%;
  height: 58%;
}

.popup-content-copyright-sm {
  width: 86%;
  height: 35%;
  min-height: 500px;
}

.popup-text-sm {
  height: 68%;
  overflow: scroll;

  p {
    margin: 0;
  }

  &.announcement {
    font-size: 14px;
  }

  &.info {
    font-size: 24px;
    max-height: 52%;
  }

  &.lang-zh,
  &.lang-sc {
    font-size: 3.7vw;
    line-height: 1.3;
  }

  @media only screen and (max-width: 628px) {
    font-size: 20px;

    &.info {
      max-height: 44%;
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 503px) {
    font-size: 18px;

    &.info {
      max-height: 38%;
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 479px) {
    font-size: 14px;

    &.info {
      max-height: 38%;
      font-size: 14px;
    }
  }
}

.text-subtitle-sm {
  font-size: 14px;
  text-decoration: underline;
  font-weight: bold;
}

.popup-divider-sm {
  height: 10px;
  border-top-width: 4px;
  border-top-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #3ad4d5, #ffffff80);
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: 8px 0px;
}

.popup-arrow-sm {
  font-size: 40px;
  color: #b7b7b7;
  text-align: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.popup-title-sm {
  font-size: 40px;
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.3;
  margin: 24px auto;

  &.lang-en {
    font-family: 'Didot';
  }

  &.lang-zh,
  &.lang-sc {
    width: 70%;
    font-size: 6vw;
  }

  @media only screen and (max-width: 628px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 503px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 479px) {
    font-size: 26px;
  }
}

.scroll-arrow-sm {
  margin: 32px 0px;

  @media only screen and (max-width: 479px) {
    margin: 16px 0px;
  }

  img {
    width: 42px;

    @media only screen and (max-width: 479px) {
      width: 32px;
    }
  }
}

.disclaimer_link {
  text-align: center;

  a {
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1;
    padding: 5px 35px 10px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    background-color: transparent;
    display: inline-block;
    text-align: center;
    width: auto;
    margin: 0 auto;
    text-decoration: none;
    outline: none;
  }
}
