.domc-wrapper {
}

.nav-list-wrapper-domc {
  width: 34%;
  top: 34%;
  right: 2%;
  min-width: 440px;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 85%;
    min-width: unset;
    left: 12%;
    margin-top: 172%;
  }
}

.nav-list-divider-domc {
  color: #73ffa9;

  @media only screen and (max-width: 820px) {
    margin: 6% 0
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-domc  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-domc {
  font-size: calc(24px + 2vw);
  color: #73ffa9;

  @media only screen and (max-width: 820px) {
    font-size: 7vw !important;
  }

  @media only screen and (max-width: 414px) {
  }
}
