.menu-background-wrapper {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
