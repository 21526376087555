.menu-wrapper {
  position: absolute;
  width: 60vw;
  left: 25vw;
  margin: 20px 0px;
  font-size: calc(30px + 0.6vw);
  z-index: 10;
  overflow-x: scroll;

  &.drawer {
    width: 100%;
    left: unset;
    text-align: right;
    top: 50px;
    right: 30px;
    font-size: 20px;

    @media only screen and (max-width: 820px) {
      font-size: 6vw;
      top: unset;
      right: unset;
      text-align: left;
    }
  }

  @media only screen and (min-width: 769px) {
    min-width: 650px;
  }

  @media only screen and (max-width: 1228px) {
    left: 300px;

    &.drawer {
      left: unset;
    }
  }

  @media only screen and (max-width: 820px) {
    position: relative;
    margin-top: 26%;
    left: 5%;
    width: 90%;
    font-size: 5.5vw;
    -webkit-text-stroke: unset;

    &.drawer {
      left: unset;
      .menu-item {
        opacity: 0.3;
      }
      .menu-item-selected {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 630px) {
    &.drawer {
      left: unset;
    }
  }
}

.menu-item {
  text-decoration: unset;
  color: transparent;
  -webkit-text-stroke: 1px white;
  text-shadow: none;
  font-weight: 200;

  &.lang-zh,
  &.lang-sc {
    color: white;
    text-shadow: none;
  }

  &.menu-item-selected {
    -webkit-text-stroke: unset;
    @media only screen and (max-width: 820px) {
    }
  }

  @media only screen and (max-width: 820px) {
    color: white;
    -webkit-text-stroke: unset;
  }

  &:hover {
    -webkit-text-stroke: unset;
  }
}

.link-list-wrapper {
  height: 75vh;
  overflow-y: scroll;

  @media only screen and (max-width: 820px) {
    height: auto;

    &.drawer {
    }
  }
}

.menu-upper {
  position: relative;
  height: 94px;

  &.drawer {
    height: 70px;
    margin-bottom: 16px;
  }

  &.lang-zh,
  &.lang-sc {
    line-height: 1.3;
  }

  img {
    width: 44%;
  }

  @media only screen and (max-width: 1228px) {
    min-width: 560px;

    &.drawer {
      width: unset;
    }
  }

  @media only screen and (max-width: 820px) {
    min-width: unset;
    width: 100%;
    height: 16vw;
    text-decoration: unset;

    img {
      width: 56% !important;
    }

    &.drawer {
      width: 100%;
      height: 16vw;

      img {
        width: 55% !important;
        left: 0 !important;
      }

      &.lang-zh,
      &.lang-sc {
        img {
          width: 65% !important;
        }
      }
    }
  }
}

.menu-item-span-show-flat {
  padding-left: 44%;

  @media only screen and (max-width: 820px) {
    padding-left: 68% !important;
    top: -2px;

    &.isDrawer {
      top: -4px;
    }
  }
  @media only screen and (max-width: 820px) {
    padding-left: 58% !important;
  }
  @media only screen and (max-width: 414px) {
    padding-left: 60% !important;
  }
}

.menu-item-span-sales-office {
  padding-left: 50%;

  @media only screen and (max-width: 820px) {
    top: 5.4vw !important;
    padding-left: 58% !important;

    &.isDrawer.lang-en {
      top: 4vw !important;
    }
  }

  @media only screen and (max-width: 414px) {
    padding-left: 56% !important;
  }
}

.menu-lower {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 10px;

  @media only screen and (max-width: 1228px) {
    width: 620px;

    &.drawer {
      width: unset;
    }
  }

  @media only screen and (max-width: 820px) {
    width: 100%;
    font-size: 5.4vw;
    padding-left: 0;
    margin-top:24px;
  }
}

.link-wrapper {
  display: flex;
  min-height: 5vw;
  height: 5vw;
  cursor: pointer;

  &:first-child {
    margin-top: 32px;
  }

  &.drawer {
    margin-bottom: 14px;
    height: 32px;
    justify-content: flex-end;

    @media only screen and (max-width: 820px) {
      height: 8vw;
      justify-content: flex-start;
    }
  }

  @media only screen and (min-width: 769px) {
    min-height: 36px;
  }

  @media only screen and (max-width: 820px) {
    margin: 4.5vw 0;
    height: unset;
    line-height: 1;
    align-items: center;

    &.drawer {
      margin: 4vw 0;
    }
  }

  @media only screen and (max-width: 539px) {
  }

  @media only screen and (max-width: 479px) {
  }
}

.scroll-hint {
  color: rgba(255, 255, 255, 0.5);
  font-size: calc(2px + 0.8vw);
  text-shadow: 0 0 white;
  margin-top: 4%;
}

.icon-hint-arrow {
  width: 10px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.menu-item-show-flat {
  cursor: pointer;

  @media only screen and (max-width: 820px) {
    font-size: 5vw;
  }
  &:hover {
    color: #ff47d9 !important;
  }
}

.menu-item-sales-office {
  cursor: pointer;

  @media only screen and (max-width: 820px) {
    font-size: 5vw;
  }
  &:hover {
    color: #009894 !important;
  }
}

.menu-item-sales-brochure:hover {
  color: #ff803e !important;
}

.menu-item-price-list:hover {
  color: #ad2078 !important;
}

.menu-item-sales-rrangements:hover {
  color: #ceff30 !important;
}

.menu-item-register-of-transactions:hover {
  color: #ffcd58 !important;
}

.menu-item-deed-of-mutual-covenant:hover {
  color: #73ff9f !important;
}

.menu-item-aerial-photograph:hover {
  color: #80a2ff !important;
}

.menu-item-tender-document:hover {
  color: #ff803e !important;
}

.menu-item-registration:hover {
  color: #b6ff66 !important;
}

.menu-item-ballot-result:hover {
  color: #cf483b !important;
}

.menu-item-sales-brochure-for-parking-space {
  @media only screen and (max-width: 820px) {
    width: 70%;
  }
}

.menu-item-sales-brochure-for-parking-space:hover {
  color: #ecb672 !important;
}

.menu-item-parking-space-document:hover {
  color: #64ff9e !important;
}
