.home-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media only screen and (max-width: 1179px) {
    height: inherit;
  }

  @media only screen and (max-width: 820px) {
    margin-bottom: 20%;
  }
}
