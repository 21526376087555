.sb-wrapper {
}

.nav-list-wrapper-sb {
  width: 24%;
  min-width: 300px;
  top: 46%;
  right: 13%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 80%;
    min-width: unset;
    left: 12%;
    right: unset;
    margin-top: 160%;
  }
}

.nav-list-divider-sb {
  color: #ff803e;

  @media only screen and (max-width: 820px) {
    margin: 6% 0px;
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-sb  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-sb {
  font-size: calc(28px + 2vw);
  color: #ff803e;

  @media only screen and (max-width: 820px) {
    font-size: 7vw!important;
  }

  @media only screen and (max-width: 414px) {
  }
}
