.footer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 200%;
  bottom: 0;
  left: 0;
  padding: 2% 4%;
  transform-origin: 0 100% 0;
  transform: scale(0.48);
  min-width: 2560px;
  overflow: hidden;
  z-index: 20;
}

.footer-left {
  display: flex;
  width: 39%;
  font-size: 20px;
  margin-right: 20px;
  flex-direction: row;
  justify-content: flex-start;
}

.brand-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  margin-right: 20px;
  min-width: 320px;
}

.brand-logo {
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 300px;
  @media only screen and (max-width: 820px) {
    width: auto;
  }
}

.zh .brand-logo {
  background-image: url('/img/shk-logo.png');
}

.en .brand-logo {
  background-image: url('/img/shk-logo.png');
}

.sc .brand-logo {
  background-image: url('/img/shk-logo-sc.png');
}

.phase {
  width: 300px;
  border: 2px solid white;
  padding: 2px;
  text-align: center;
  margin-top: 5%;
  font-size: 1.2vw;
  cursor: pointer;
  height: 1.6vw;
  line-height: 1.8vw;
}

.lang-wrapper {
  width: 100%;
  font-size: 24px;
  align-self: center;
  text-align: center;
}

.lang-option {
  margin: 0px 8px;
  cursor: pointer;
}

.sound-icon {
  min-width: 42px;
  align-self: center;
  margin-left: 25px;
  color: white;
  cursor: pointer;
}

.ig-icon {
  width: 70px;
  align-self: center;
  margin-left: 12px;
  cursor: pointer;
}

.footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  .en {
    font-family: 'Roboto Condensed', sans-serif;
  }
}

.copyright-trade-mark {
  cursor: pointer;
  text-decoration: underline;
}

.disclaimer {
  font-size: 18px;

  &.en {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
}

.more-info {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  .en {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
}

.actions-wrapper {
  display: flex;
  align-items: center;
  margin: 0 50px;
  font-size: 0;
}

.information-wrapper {
  width: 50%;
  font-size: 18px;
  line-height: 1.3;
  align-self: center;
  padding: 0px 10px;
  text-decoration: underline;
  cursor: pointer;
  flex: 1;
}
