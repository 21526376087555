.rot-wrapper {
}

.nav-list-wrapper-rot {
  width: 31%;
  min-width: 400px;
  top: 36%;
  left: 16%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 85%;
    min-width: unset;
    left: 10%;
    margin-top: 172%;
  }
}

.nav-list-divider-rot {
  color: #fcbd57;

  @media only screen and (max-width: 820px) {
    margin: 6% 0px;
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-rot {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-rot {
  font-size: calc(24px + 2vw);
  color: #fcbd57;

  @media only screen and (max-width: 820px) {
    font-size: 7vw!important;
  }

  @media only screen and (max-width: 414px) {
  }
}
