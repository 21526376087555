.psd-wrapper {
}

.nav-list-wrapper-psd {
  width: 30%;
  min-width: 400px;
  top: 45%;
  left: 54%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 80%;
    min-width: unset;
    left: 12%;
    margin-top: 180%;
  }
}

.nav-list-divider-psd {
  color: #64ffa8;

  @media only screen and (max-width: 820px) {
    margin: 6% 0px;
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-psd  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-psd {
  font-size: calc(24px + 2vw);
  color: #64ffa8;

  @media only screen and (max-width: 820px) {
    font-size: 7vw!important;
  }

  @media only screen and (max-width: 414px) {
  }
}
