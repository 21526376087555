.sbfps-wrapper {
}

.nav-list-wrapper-sbfps {
  width: 42%;
  min-width: 480px;
  top: 24%;
  left: 10%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 80%;
    min-width: unset;
    left: 15%;
    margin-top: 170%;
  }

  @media only screen and (max-width: 414px) {
  }
}

.nav-list-divider-sbfps {
  color: #f6c072;

  @media only screen and (max-width: 820px) {
    margin: 6% 0px;
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-sbfps  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-sbfps {
  font-size: calc(28px + 2vw);
  color: #f6c072;

  @media only screen and (max-width: 820px) {
    font-size: 7vw!important;
  }

  @media only screen and (max-width: 414px) {
  }
}
