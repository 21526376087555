.slider-wrapper {
  .slick-slide {
    > div {
      margin: 10px;
    }
  }
}

.empty-list {
  .slick-slide {
    > div {
      text-align: right;
    }
  }
}

.slider-title-wrapper {
  display: flex;
  justify-content: flex-end;

  .slider-title {
    font-size: 56px;
    line-height: 1;
    animation: text-mask 0.8s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    clip-path: inset(100% 0% 0%);
  }
}

.slider-item {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .play-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80px;
    height: 80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: auto;
    z-index: 2;
    transition-duration: 0.8s;
    -webkit-transition-duration: 0.8s;
    background-color: transparent;
    transition-property: background-color;
    -webkit-transition-property: background-color;
  }
}

.thumbnail-img {
  width: 100%;
  min-height: 300px;
  max-height: 320px;

  @media only screen and (max-width: 820px) {
    min-height: unset;
    max-height: unset;
  }
}

.slider-item:hover {
  .play-btn {
    background-color: rgba(25, 105, 117, 0.8);
  }
}

.item-title {
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 12px 0;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);

  &.width-90 {
    width: 90%;
  }
}

.empty-list-text {
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  color: white;
  font-size: 16px;
  word-break: break-word;
  text-align: left;
}
