.scroll-arrow {
  color: #b7b7b7;
  text-align: center;
  margin-bottom: 8px;
  cursor: pointer;

  img {
    width: 20px;
  }
}
