.menu-drawer-wrapper {
  position: absolute;
  width: 460px;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: rgb(56 27 67 / 80%);
  z-index: 40;
  transition: all 0.5s;

  @media only screen and (max-width: 820px) {
    width: 86%;
    margin: 0 2%;
    height: 120%;
    padding: 0 5%;
    background: #381B42;
  }
}

.close-icon,
.open-icon {
  position: absolute;
  margin: 2.3% 2.9%;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 30px;
  min-width: 30px;

  @media only screen and (max-width: 820px) {
    margin: 5%;
    min-width: unset;
  }
}
