.nav-list-wrapper-registration {
  width: 36%;
  min-width: 440px;
  top: 42%;
  left: 7%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 62%;
    min-width: unset;
    left: 6%;
    margin-top: 158%;
  }

  @media only screen and (max-width: 414px) {
    width: 80%;
  }
}

.nav-list-divider-registration {
  color: #c0ff66;

  @media only screen and (max-width: 820px) {
    margin: 6% 0px;
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-registration  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-registration {
  font-size: calc(36px + 2vw);
  color: #d8ff30;

  @media only screen and (max-width: 820px) {
    font-size: 7vw !important;
  }
}

.registration-item-wrapper {
  display: grid;
  grid-template-columns: 4fr 1fr;

  @media only screen and (max-width: 414px) {
    grid-template-columns: 3fr 1fr;
  }

  &.en {
    grid-template-columns: 3fr 1fr;

    @media only screen and (max-width: 414px) {
      grid-template-columns: 2fr 1fr;
    }
  }
}

.registration-item-link-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registration-file-link-container {
  text-align: right;
}

.arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-right {
  width: 14px;

  @media only screen and (max-width: 414px) {
    width: 12px;
    height: 18px;
  }
}

.arrow-down {
  height: 14px;

  @media only screen and (max-width: 414px) {
    height: 12px;
  }
}
