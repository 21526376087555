.td-wrapper {
}

.nav-list-wrapper-td {
  width: 30%;
  min-width: 360px;
  top: 30%;
  left: 7%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 86%;
    min-width: unset;
    left: 7%;
    margin-top: 160%;
  }
}

.nav-list-divider-td {
  color: #ff803e;

  @media only screen and (max-width: 820px) {
    margin: 6% 0px;
  }
}

.zh, .sc {
  .nav-list-title-td  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-td {
  font-size: calc(36px + 2vw);
  color: #ff803e;

  @media only screen and (max-width: 820px) {
    font-size: 7vw!important;
  }

  @media only screen and (max-width: 414px) {
  }
}
