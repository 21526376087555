.lang-ig-select-wrapper {
  position: absolute;
  width: 200px;
  left: 16%;
  top: 78%;
  font-size: 22px;

  span {
    cursor: pointer;
    margin-right: 30px;
  }

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 100%;
    left: 0;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 175%;

    span {
      margin-right: 40px;
    }

    &.in-drawer {
      margin-top: 5%;
      justify-content: flex-start;
    }
  }

  @media only screen and (max-width: 494px) {
    font-size: 20px;

    span {
      margin-right: 30px;
    }
  }
}

.icon-ig {
  width: 50px;
  @media only screen and (max-width: 820px) {
    width: 35px;
  }
}
