.app-wrapper {
  width: 100vw;
  height: 100vh;

  @media only screen and (max-width: 820px) {
    height: auto;
    min-height: 100vh;
  }
}

.bkg-img {
  position: absolute;
  width: calc((100vh * 16) / 9);
  min-width: 100vw;
  min-height: 100vh;

  @media only screen and (max-width: 820px) {
  }
}

.section-wrapper {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  @media only screen and (max-width: 820px) {
    width: 100vw;
    margin-bottom: 20%;
    min-height: unset;
    overflow: unset;
  }
}

div {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
