.br-wrapper {
}

.nav-list-wrapper-br {
  width: 36%;
  min-width: 450px;
  top: 32%;
  right: 6%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 85%;
    min-width: unset;
    left: 8%;
    margin-top: 158%;
  }

  @media only screen and (max-width: 414px) {
  }
}

.nav-list-divider-br {
  color: #d9483b;

  @media only screen and (max-width: 820px) {
    margin: 6% 0
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-br  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-br {
  font-size: calc(30px + 2vw);
  color: #d9483b;

  @media only screen and (max-width: 820px) {
    font-size: 7vw !important;
  }

  @media only screen and (max-width: 414px) {
  }
}
