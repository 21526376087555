.entrance-wrapper {
  @media only screen and (min-width: 769px) {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/img/bkg_entrance.jpg');
  }
}

.entrance-logo-container {
  position: absolute;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 40px;
  width: 40%;
  left: 15%;
  top: 10%;
  @media only screen and (max-width: 820px) {
    width:80%;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    row-gap: 80px;
  }
}

.bkg-img-entrance {
  width: calc((100vh * 16) / 9);
  min-width: 100vw;
  width: 100vw;
}

.yohohub-img {
  width: 96%;
  cursor: pointer;

  @media only screen and (max-width: 820px) {
    width: 90%;
  }
}

.yohohub2-img {
  width: 100%;
  cursor: pointer;

  @media only screen and (max-width: 820px) {
    width: 90%;
  }
}

.yoho-phase-b-desc {
  width: 65%;
  text-align: center;
  line-height: 1.3;
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
}

.yoho-phase-desc-name {
  font-size: 14px;
  margin: 0 auto;
  width:90%;
  opacity: 0.8;

  @media only screen and (max-width: 690px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 479px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 379px) {
    font-size: 10px;
  }
}

.yoho-phase-desc-remark {
  font-size: 8px;
  opacity: 0.6;
  margin: 5px auto;
  width: 85%;

  @media only screen and (max-width: 690px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 10px;
  }

  @media only screen and (max-width: 479px) {
    font-size: 8px;
  }

  @media only screen and (max-width: 379px) {
    font-size: 16px;
    width: 250%;
    transform-origin: left;
    transform: scale(0.4);
    -webkit-transform-origin-y: top;
  }
}

.yoho-phase-c-desc {
  width: 65%;
  text-align: center;
  line-height: 1.3;
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
}

.remark {
  position: absolute;
  right: 1%;
  bottom: 1%;
  font-size: 12px;
  display: inline-block;
  @media only screen and (max-width: 820px) {
    left: 7%;
    right: unset;
    font-size: 8px;
    bottom: 3%;
  }
}
