.sf-wrapper {
}

.nav-list-wrapper-sf {
  width: 34%;
  min-width: 280px;
  top: 46%;
  left: 5%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 85%;
    min-width: unset;
    margin-top: 20%;
    left: 5%;
    .list-file-name {
      width: 100%;
    }
  }
}

.nav-list-divider-sf {
  color: #ff4db0;

  @media only screen and (max-width: 820px) {
    margin: 6% 0;
  }
}

.zh, .sc {
  .nav-list-title-sf  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-sf {
  font-size: calc(28px + 2vw);
  color: #ff4db0;

  @media only screen and (max-width: 820px) {
    font-size: 8vw !important;
  }

  @media only screen and (max-width: 414px) {
  }
}

.video-sf {
  position: absolute;
  top: 18%;
  left: 34%;
  width: 780px;
}

.sf-content {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 8%;
  left: 10%;
  overflow: scroll;

  @media only screen and (max-width: 820px) {
    position: relative;
    overflow: unset;
    width: 100%;
    left: 5%;
  }
}

.iframe-wrapper-sf {
  position: absolute;
  width: 50vw;
  height: 100%;
  right: 0;
  display: flex;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 100%;
    min-width: unset;
    margin-top: 80%;

    &.popup-visible {
      margin-top: 10%;
    }
  }
}

.iframe-sf {
  width: 50vw;
  height: calc((50vw * 9) / 16);
  align-self: center;

  @media only screen and (max-width: 820px) {
    width: 90vw;
    height: calc((90vw * 9) / 16);
  }
}
