@keyframes allready-all {
  from {
    clip-path: inset(100% 0% 0%);
    transform: translateY(15%);
    opacity: 0;
  }

  to {
    clip-path: inset(0% 0% 0%);
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes allready-ready {
  from {
    clip-path: inset(100% 0% 0%);
    transform: translateY(10%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    clip-path: inset(0% 0% 0%);
    opacity: 1;
  }
}

.all-ready-img-wrapper {
  position: absolute;
  width: 800px;
  height: 350px;
  top: 45%;
  left: 18%;
  transform: translateY(-50%);
  @media only screen and (min-width: 1920px) {
    top: 35%;
  }
  @media only screen and (max-width: 820px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.all-ready-img-content {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;

  .all {
    position: absolute;
    top: 0;
    left: 26%;
    width: 220px;
    padding-top: 100%;
    animation: allready-all 0.8s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    clip-path: inset(100% 0% 0%);
    background-size: contain;
    background-repeat: no-repeat;
    @media only screen and (min-width: 1920px) {
      width: 350px;
    }
    @media only screen and (max-width: 820px) {
      width: 32%;
      left: 30%;
      top: -33%;
      transform: translate(-50%, 0);
  }
}
  .ready {
    position: absolute;
    top: 24%;
    left: 10%;
    width: 540px;
    padding-top: 100%;
    animation: allready-ready 0.8s;
    animation-fill-mode: forwards;
    animation-delay: 1.2s;
    clip-path: inset(100% 0% 0%);
    background-size: contain;
    background-repeat: no-repeat;
    @media only screen and (min-width: 1920px) {
      top: 39%;
      left: 1%;
      width: 850px;
    }
    @media only screen and (max-width: 820px) {
      width: 85%;
      left: 9%;
      top: -17%;
      transform: translate(-50%, 0);
    }
  }
  @media only screen and (max-width: 820px) {
    position: relative;
    width: 90%;
    left: 5%;
  }
}
