.popup-wrapper {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 15;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.popup-frame-img {
  width: 100%;
  height: 100%;
  // max-width: 1462px;
  // max-height: 93vh;
}

@keyframes popup-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.popup-content-wrapper {
  width: 76%;
  height: 90vh;
  top: 0;
  left: 0;
  right: 0;
  margin: 10px auto;
  font-size: 12px;
  opacity: 0;
  animation: popup-animation 1.2s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;

  &.lang-en {
    font-family: 'Helvetica Neue', 'Arial', sans-serif;
  }
}

.popup-content {
  position: absolute;
  top: 28%;
  left: 33%;
  margin: auto;
  z-index: 10;
  width: 37%;
  height: 50%;
}

.popup-content-copyright {
  width: 34%;
  height: 35%;
}

.popup-text {
  height: 51%;
  overflow: scroll;

  p {
    margin: 0px;
  }

  @media only screen and (max-width: 1228px) {
    &.info {
      max-height: 60%;
    }
  }

  @media only screen and (max-width: 790px) {
    &.info {
      max-height: 52%;
    }
  }
}

.text-subtitle {
  font-size: 12px;
  text-decoration: underline;
  font-weight: bold;
}

.popup-divider {
  height: 10px;
  border-top-width: 2px;
  border-top-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #3ad4d5, #ffffff80);
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.popup-arrow {
  font-size: 14px;
  color: #b7b7b7;
  text-align: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.popup-title {
  font-size: 26px;
  text-align: center;
  margin-bottom: 14px;
  line-height: 1.3;

  &.lang-en {
    font-family: 'Didot';
  }
}

.disclaimer_link {
  text-align: center;

  a {
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1;
    padding: 5px 35px 10px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    background-color: transparent;
    display: inline-block;
    text-align: center;
    width: auto;
    margin: 0 auto;
    text-decoration: none;
    outline: none;
  }
}
