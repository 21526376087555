.ap-wrapper {
}

.nav-list-wrapper-ap {
  width: 26%;
  min-width: 340px;
  top: 8%;
  left: 20%;

  @media only screen and (max-width: 820px) {
    position: relative;
    width: 82%;
    min-width: unset;
    left: 10%;
    margin-top: 170%;
  }
}

.nav-list-divider-ap {
  color: #80acff;

  @media only screen and (max-width: 820px) {
    margin: 6% 0px;
  }

  @media only screen and (max-width: 414px) {
  }
}

.zh, .sc {
  .nav-list-title-ap  {
    font-size: calc(0px + 2vw);
  }
}

.nav-list-title-ap {
  font-size: calc(20px + 2vw);
  color: #80acff;

  @media only screen and (max-width: 820px) {
    font-size: 7vw !important;
  }

  @media only screen and (max-width: 414px) {
  }
}
