.footer-sm {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  transform-origin: unset;
  transform: unset;
  padding: 0;
  font-size: 14px;
  z-index: 35;

  &.lang-en {
    font-family: 'Helvetica Neue', 'Arial', sans-serif;
  }

  &.lang-zh,
  &.lang-sc {
    line-height: 1.3;
  }
}

.disclaimer-sm {
  width: 98%;
  font-size: 8px;
  margin-left: 12%;
  transform-origin: 0 100% 0;
  transform: scale(0.8);
  text-align: justify;

  &.lang-zh,
  &.lang-sc {
    font-size: 3vw;
    text-align: left;
  }
}

.information-wrapper-sm {
  width: 60%;
  margin-left: 20%;
  text-decoration: underline;
  font-size: 18px;
  text-align: center;

  &.lang-zh,
  &.lang-sc {
    width: 42%;
    margin-left: 29%;
    font-size: 4vw;
  }

  @media only screen and (max-width: 414px) {
    width: 70%;
    margin-left: 15%;
    font-size: 16px;
  }
}

.scope {
  width: 76%;
  margin-left: 12%;
  font-size: 12px;
  text-align: justify;

  &.lang-zh,
  &.lang-sc {
    font-size: 2.8vw;
    text-align: left;
  }
}

.phase-name {
  width: 76%;
  margin-left: 12%;

  &.lang-zh,
  &.lang-sc {
    font-size: 3.3vw;
  }
}

.district {
  width: 76%;
  margin-left: 12%;

  &.lang-zh,
  &.lang-sc {
    font-size: 3.3vw;
  }
}

.disclaimer-sm2 {
  width: 76%;
  margin-left: 12%;

  &.lang-zh,
  &.lang-sc {
    font-size: 2.8vw;
    text-align: left;
  }
}

.company-name {
  width: 100%;
  text-align: center;

  &.lang-zh,
  &.lang-sc {
    font-size: 3.3vw;
  }
}

.last-updated {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.brand-logo-wrapper-sm {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 5% 30% 2% 30%;
}

.brand-logo-sm {
  width: 100%;
  align-self: center;
}

.phase-sm {
  border: 1px solid white;
  padding: 2px;
  text-align: center;
  margin-top: 5%;
  font-size: 2.6vw;
  cursor: pointer;
  height: 3vw;
  line-height: 3vw;

  @media only screen and (max-width: 414px) {
    height: 6vw;
    line-height: 6vw;
  }
}
