
@font-face {
  font-family: 'AdobeMingStd-Light';
  src: url('./fonts/AdobeMingStd-Light.otf') format("opentype");;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoCondensed-Light';
  src: url('./fonts/RobotoCondensed-Light.ttf') format("truetype");;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SortsMillGoudy-Regular';
  src: url('./fonts/SortsMillGoudy-Regular.ttf') format("truetype");;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vanity-Light';
  src: url('./fonts/Vanity-Light.otf') format("opentype");;
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
