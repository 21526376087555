.nav-list-wrapper {
  position: absolute;
  z-index: 10;
}

.nav-list-divider {
  margin: 16px 0;
  border-top: 2px solid;
  display: none;

  &.lang-zh,
  &.lang-sc {
    margin: 16px 0 !important;
  }
}

.nav-list-arrow {
  color: #b7b7b7;
  text-align: center;
  margin-bottom: 10px;
}

@keyframes list-content {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.nav-list-content-wrapper {
  min-height: 40px;
  overflow: scroll;
  padding: 10px 2px;
  color: white;
  font-size: 16px;
  word-break: break-word;
  opacity: 0;
  max-width: 600px;
  animation: list-content 1.5s;
  animation-fill-mode: forwards;
  animation-delay: 1s;

  &.lang-en {
    font-family: 'Helvetica Neue', 'Arial', sans-serif;
  }
}

.list-file-name {
  width: 60%;
  display: inline-block;

  &.width-90 {
    width: 90%;
  }
}

.list-file-link-container {
  display: inline-block;
  padding-left: 50px;
  @media only screen and (max-width: 820px) {
    padding-left: 12px;
  }
}

.list-file-link {
  color: white;
  text-decoration: unset;
}

.list-wrapper {
  max-height: 145px;
  height: 100%;
}

.list-item-wrapper {
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
}

@keyframes text-mask {
  from {
    clip-path: inset(100% 0% 0%);
    transform: translateY(15%);
    opacity: 0;
  }

  to {
    clip-path: inset(0% 0% 0%);
    transform: translateY(0%);
    opacity: 1;
  }
}

.list-title {
  font-size: 56px;
  line-height: 1;
  animation: text-mask 0.8s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  clip-path: inset(100% 0% 0%);
}
